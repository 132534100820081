import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import usaFlag from "../assets/images/flag/USA.png";
import indiaFlag from "../assets/images/flag/download.png";
import uaeFlag from "../assets/images/flag/Flag_of_the_United_Arab_Emirates.svg.png";
import bfaglogo from "../assets/images/BFAG_logo.png";
import { FiPhone, FiMail } from "react-icons/fi";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Model from "../component/Model/Model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const [showModal, setShowModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(
    localStorage.getItem("selectedCountry") || "USA"
  );
  const [isIndia, setIsIndia] = useState(false);

  const navbarCollapseRef = useRef(null); // Ref for the navbar collapse element

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    } else {
      localStorage.setItem("selectedCountry", "USA");
      setSelectedCountry("USA");
    }
    setIsIndia(storedCountry === "India");
  }, []);

  const navigate = useNavigate();

  const handleServicesClick = (e) => {
    e.preventDefault();
    navigate("/Services");
  };

  const changeCountry = (country) => {
    setSelectedCountry(country);
    localStorage.setItem("selectedCountry", country);
    window.location.reload();
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
    setActiveSubmenu(null); // Close any open submenu when toggling dropdown
  };

  const toggleSubmenu = (submenu) => {
    setActiveSubmenu((prev) => (prev === submenu ? null : submenu));
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".nav-item.dropdown")) {
      setActiveDropdown(null);
      setActiveSubmenu(null);
    }
  };

  // Function to close the navbar
  const closeNavbar = () => {
    if (navbarCollapseRef.current) {
      navbarCollapseRef.current.classList.remove("show");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <>
      <style>
        {`
          header {
            width: 100%;
            padding: 0;
            margin: 0;
            box-sizing: border-box;
          }
          .dropdown-menu {
            background-color: #f8f9fa;
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1000;
            display: none;
            opacity: 0;
            transition: opacity 0.3s ease, visibility 0.3s ease;
          }

          .dropdown-menu.show {
            display: block;
            opacity: 1;
            visibility: visible;
          }

          .dropdown-item {
            color: #343a40;
            padding: 0.5rem 1rem;
            transition: background-color 0.2s, color 0.2s;
            font-size: 16px;
          }

          .navbar-toggler-icon {
            color: #000;
          }

          .nav-link {
            position: relative;
            transition: color 0.5s;
            font-size: 16px;
            padding: 0.5rem 0.8rem;
          }

          .navbar {
            padding: 0.5rem 1rem;
          }

          .nav-link:hover::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            transition: width 0.5s;
          }

          .dropdown-toggle::after {
            display: none;
          }

          .nav-item.dropdown:hover .dropdown-menu {
            display: block;
          }

          .nav-item.dropdown .dropdown-menu:hover {
            display: block;
          }

          .dropdown-menu .dropdown-item {
            position: relative;
            padding-left: 1.5rem;
          }

          .nav-item.dropdown {
            position: relative;
          }

          .dropdown-menu .dropdown-item:hover::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #072f5f;
          }

          .navbar-toggler-icon {
            background-color: #000;
          }

          .navbar-brand img {
            width: 160px;
            height: 30px;
          }

          .dropdown-item:focus, .dropdown-item:active {
            background-color: transparent !important;
            color: #343a40;
          }

          .btn.btn-primary {
            padding: 7px 14px;
            font-size: 14px;
          }

          .navbar-toggler {
            padding: 0.25rem 0.5rem;
          }

          @media (max-width: 768px) {
            .navbar {
              position: fixed;
              top: 0;
              width: 100%;
              z-index: 1030;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0.5rem 1rem;
              background-color: #fff;
            }
            .navbar-collapse {
              position: absolute;
              top: 60px;
              left: 0;
              right: 0;
              z-index: 1020;
              background-color: #fff;
              width: 100%;
            }
            .navbar-collapse.show {
              display: block;
              overflow: hidden;
              height: auto;
              transition: height 0.5s ease;
            }

            .fixed-flag-dropdown {
              display: flex;
              align-items: center;
            }

            .navbar-brand img {
              width: 140px;
              height: 30px;
            }

            .top-header {
              display: none;
            }
            .nav-link {
              font-size: 12px;
              padding: 0.5rem 0.6rem;
            }

            .dropdown-item {
              font-size: 15px;
            }
            .dropdown-toggle::after {
              display: none;
            }

            .btn-secondary.dropdown-toggle img {
              width: 26px;
              height: 18px;
            }

            .btn.btn-primary {
              padding: 6px 12px;
              font-size: 14px;
            }
          }
        `}
      </style>

      <header className="text-white top-header" style={{ backgroundColor: "#0B0C26" }}>
        <div className="container py-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-3">
              <FiPhone className="text-white" />
              <a href="tel:+14085331050" className="text-decoration-none" style={{ color: "#fff", fontSize: "14px" }}>
                <h3 className="text-sm pt-2" style={{ fontSize: "14px", color: "#fff" }}>Phone: +1-408-533-1050</h3>
              </a>
              <FiMail className="text-white" />
              <a href="mailto:info@bostonfagroup.com" className="text-decoration-none" style={{ color: "#fff", fontSize: "14px" }}>
                <h3 className="text-sm pt-2" style={{ fontSize: "14px", color: "#fff" }}>Email: info@bostonfagroup.com</h3>
              </a>
            </div>
            <div className="d-flex align-items-center gap-3 ms-auto">
              <a href="https://www.facebook.com/BostonFinancialAdvisoryGroup/" target="_blank" rel="noopener noreferrer">
                <FaFacebookF className="text-white" />
              </a>
              <a href="https://twitter.com/BostonfaGroup" target="_blank" rel="noopener noreferrer">
                <FaTwitter className="text-white" />
              </a>
              <a href="https://www.linkedin.com/company/boston-financial-advisory-group/" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn className="text-white" />
              </a>
            </div>
          </div>
        </div>
      </header>

      <header className="bg-light sticky-top">
        <div className="container-fluid px-0">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link to="/" className="navbar-brand">
              <img src={bfaglogo} alt="boston" style={{ width: "220px", height: "40px" }} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon icon={faBars} style={{ color: "#000", fontSize: "24px" }} />
            </button>

            <div className="collapse navbar-collapse" id="navbarNav" ref={navbarCollapseRef}>
              <ul className="navbar-nav ms-auto pr-5">
                <li className={`nav-item dropdown ${activeDropdown === "about" ? "show" : ""}`}>
                  <Link
                    className="nav-link dropdown-toggle text-dark text-decoration-none d-flex align-items-center"
                    to="#"
                    role="button"
                    aria-expanded={activeDropdown === "about" ? "true" : "false"}
                    onClick={() => toggleDropdown("about")}
                    style={{ letterSpacing: "0.5px" }}
                  >
                    About
                    <i
                      className={`ms-2 fas ${activeDropdown === "about" ? "fa-chevron-up" : "fa-chevron-down"}`}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </Link>
                  <ul className={`dropdown-menu ${activeDropdown === "about" ? "show" : ""}`}>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/whybfg"
                        onClick={() => {
                          toggleSubmenu("why");
                          closeNavbar(); // Close navbar on click
                        }}
                      >
                        Why BFG
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/about/Ourteam"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Our Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/howwedoit"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        How We Do It
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className={`nav-item dropdown ${activeDropdown === "services" ? "show" : ""}`}>
                  <Link
                    className="nav-link dropdown-toggle text-dark text-decoration-none d-flex align-items-center"
                    to="/Services"
                    onClick={() => toggleDropdown("services")}
                    style={{ letterSpacing: "0.5px" }}
                  >
                    Services
                    <i
                      className={`ms-2 fas ${activeDropdown === "services" ? "fa-chevron-up" : "fa-chevron-down"}`}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </Link>
                  <ul className={`dropdown-menu ${activeDropdown === "services" ? "show" : ""}`}>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/accounting-and-transaction-processing"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Accounting & Transaction Processing
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/taxregulatorycompliances"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Tax & Regulatory Compliances
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/payrolladvisory"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Payroll Advisory
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/consultingandadvisoryservices"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Consulting & Advisory
                      </Link>
                    </li>
                    {isIndia && (
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/startupsolutionservices"
                          onClick={closeNavbar} // Close navbar on click
                        >
                          Startup Solution
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link text-dark text-decoration-none"
                    to="/cpabackoffice"
                    style={{ letterSpacing: "0.5px" }}
                    onClick={closeNavbar} // Close navbar on click
                  >
                    CPA Back Office
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link text-dark text-decoration-none"
                    to="/product"
                    style={{ letterSpacing: "0.5px" }}
                    onClick={closeNavbar} // Close navbar on click
                  >
                    Products
                  </Link>
                </li>

                <li className={`nav-item dropdown ${activeDropdown === "infocenter" ? "show" : ""}`}>
                  <Link
                    className="nav-link dropdown-toggle text-dark text-decoration-none d-flex align-items-center"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      toggleDropdown("infocenter"); // Toggle dropdown
                    }}
                    style={{ letterSpacing: "0.5px" }}
                  >
                    Info Center
                    <i
                      className={`ms-2 fas ${activeDropdown === "infocenter" ? "fa-chevron-up" : "fa-chevron-down"}`}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </Link>
                  <ul className={`dropdown-menu ${activeDropdown === "infocenter" ? "show" : ""}`}>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/blogs"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/casestudies"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Case Studies
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/mediahits"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Media Hits
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/careers"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/our-publication"
                        onClick={closeNavbar} // Close navbar on click
                      >
                        Our Publications
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link text-dark text-decoration-none"
                    to="/contactus"
                    style={{ letterSpacing: "0.5px" }}
                    onClick={closeNavbar} // Close navbar on click
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="d-flex align-items-center gap-3">
              <button
                className="btn btn-primary text-white d-none d-lg-block"
                style={{ backgroundColor: "#072f5f" }}
                onClick={handleShow}
              >
                Request Info
              </button>
              <div className="fixed-flag-dropdown">
                <div className="btn-group">
                  <button
                    style={{ backgroundColor: "transparent" }}
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownFlagButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        selectedCountry === "USA"
                          ? usaFlag
                          : selectedCountry === "India"
                          ? indiaFlag
                          : uaeFlag
                      }
                      alt="Selected Country Flag"
                      style={{ width: "31px", height: "22px" }}
                    />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                   
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => changeCountry("USA")}
                      >
                        <img
                          src={usaFlag}
                          alt="USA Flag"
                          // className="img-fluid"
                          style={{ width: "30px", height: "20px" }}
                        />{" "}
                        USA
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => changeCountry("India")}
                      >
                        <img
                          src={indiaFlag}
                          alt="India Flag"
                          // className="img-fluid"
                          style={{ width: "30px", height: "20px" }}
                        />{" "}
                        India
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => changeCountry("UAE")}
                      >
                        <img
                          src={uaeFlag}
                          alt="UAE Flag"
                          style={{ width: "30px", height: "20px" }}
                        />{" "}
                        UAE
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <Model show={showModal} handleClose={handleClose} />
    </>
  );
}

export default Navbar;
